
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexsrNpwRvaofR5r2ycGkVINeQhqpL6N5UMkDQB0J3YuXAMeta } from "/tmp/8dd6d5cccee4583/pages/index.vue?macro=true";
import { default as noroleOZBe5PWTN_66BM5EkIDBmeweJjxo___o8onScPfXhcAMeta } from "/tmp/8dd6d5cccee4583/pages/norole.vue?macro=true";
import { default as newFWq4zr0FOv7euui9a7nrrhDQMAqtkXF_nGSBVFQjevcMeta } from "/tmp/8dd6d5cccee4583/pages/job/new.vue?macro=true";
import { default as settingsuifP75_456qr3tLTQ0QVJF6X3PNfXZNEhLJwi_mmRaXvEMeta } from "/tmp/8dd6d5cccee4583/pages/settings.vue?macro=true";
import { default as nocompanygcN2bBVgmYFDxY8fAyRx86DWXybtOFTK8knQNXdTVMEMeta } from "/tmp/8dd6d5cccee4583/pages/nocompany.vue?macro=true";
import { default as _91id_934ClANkPoSCFp_acNTW_45kpEV_R736Z1W9AttINJzXN_45AMeta } from "/tmp/8dd6d5cccee4583/pages/apply/[id].vue?macro=true";
import { default as closedCJMeDsH7W7CwZ4DU2H6TrueIRCSgZlr7rq1A8a2xfQ8Meta } from "/tmp/8dd6d5cccee4583/pages/job/closed.vue?macro=true";
import { default as opened9oGLJfOB5vDaD_uRAPqgUjmZ8_4gi_45i_L5zCY4kleUMMeta } from "/tmp/8dd6d5cccee4583/pages/job/opened.vue?macro=true";
import { default as pausedwXawqmSEeqwQ7i3aVQfYyM_xu_45lSTQ5NyarSpNiLQVYMeta } from "/tmp/8dd6d5cccee4583/pages/job/paused.vue?macro=true";
import { default as indexZzoWmD2glNkJ3vCMjzwhtG7DHnb3a6QMbxHJ_ZRbxG4Meta } from "/tmp/8dd6d5cccee4583/pages/team/index.vue?macro=true";
import { default as index3hXZ_QaSdnjqiIprk0_45t0A688Ki5nxWJpX_453kPWpiJEMeta } from "/tmp/8dd6d5cccee4583/pages/user/index.vue?macro=true";
import { default as indexM7rHDbZKywvrYwt_Vo824Id6x0zMSYndu49TMjlk07cMeta } from "/tmp/8dd6d5cccee4583/pages/inbox/index.vue?macro=true";
import { default as index6_N_45b9X2GFzNcPfW6rowQj6_YvCVFlcJHqup3WM8jCUMeta } from "/tmp/8dd6d5cccee4583/pages/plans/index.vue?macro=true";
import { default as thankshVKYtFWr5QPn2VhoEcOHdxcF3m53X8Dad5sT4KfbVOIMeta } from "/tmp/8dd6d5cccee4583/pages/apply/thanks.vue?macro=true";
import { default as archivedeFmx3tOXcATc3Up_45zhLd316K_45eZU9QwYajDA_45YSJ0skMeta } from "/tmp/8dd6d5cccee4583/pages/job/archived.vue?macro=true";
import { default as indexZbszxocWLw7G9_45G0gYy2KusiiC8iSAE_k9weuw1cyTUMeta } from "/tmp/8dd6d5cccee4583/pages/thanks/index.vue?macro=true";
import { default as indexca3IQStoQdwmGMdM33JR320RAxXX7CtlnsMuGZazFCsMeta } from "/tmp/8dd6d5cccee4583/pages/company/index.vue?macro=true";
import { default as _91id_93He_UUqcCygq0Tl6pte6RGZjDashtaU_45xDqzt0k37EGEMeta } from "/tmp/8dd6d5cccee4583/pages/applicant/[id].vue?macro=true";
import { default as mentionsAuw9ibmmBZOrwQAiyfWMWHeNzgK1qVNfHXYh_45FJMAM8Meta } from "/tmp/8dd6d5cccee4583/pages/inbox/mentions.vue?macro=true";
import { default as indexeJYlaSuEyD6JKipThjFv4YCGWYgDUb445ZMIYXoaxbwMeta } from "/tmp/8dd6d5cccee4583/pages/job/[job]/index.vue?macro=true";
import { default as _91id_935fD_45deKJWeD_gMdpXy3P_45LoC_45Q_45rM0DBdd0031dBjdQMeta } from "/tmp/8dd6d5cccee4583/pages/apply/perfil/[id].vue?macro=true";
import { default as indexf6vVQ2dqVLH7xTCwZRymdTYHqpcwPEnjZkd7XuwMYlkMeta } from "/tmp/8dd6d5cccee4583/pages/auth/signin/index.vue?macro=true";
import { default as indexdMz0ChJINxWlZHp72dpmfFPIeBMfX1WaupWIARjwdTwMeta } from "/tmp/8dd6d5cccee4583/pages/auth/signup/index.vue?macro=true";
import { default as jobsHS6JSo1CjmcWu5AzlHlwKqbGbKRzeEy9ExPhyUjJMEwMeta } from "/tmp/8dd6d5cccee4583/pages/company/[id]/jobs.vue?macro=true";
import { default as onboardingsrAA5K3S1WDMCwP_45xvtORv7cDY5Oq9ECG90wQBBKDJsMeta } from "/tmp/8dd6d5cccee4583/pages/company/onboarding.vue?macro=true";
import { default as _91email_93JJSOOLZjk_jlm5pRIv8ataoVx3jwQ_n2EPQ5kEb6WkUMeta } from "/tmp/8dd6d5cccee4583/pages/auth/signup/[email].vue?macro=true";
import { default as duplicaterSwzD4iYO2yk71cJzINSaqYN7KUjEujAFWnOHqoBpm0Meta } from "/tmp/8dd6d5cccee4583/pages/job/[job]/duplicate.vue?macro=true";
import { default as forgotSeYF0aIESr3wb_45XLKJ9YJ8clb_45azaFVJYibtsX7VtzYMeta } from "/tmp/8dd6d5cccee4583/pages/auth/password/forgot.vue?macro=true";
import { default as _91_46_46_46slug_93dlzvaeZi13YIJRAdyOXekI12aKMoPrnSOtn5fbovAaoMeta } from "/tmp/8dd6d5cccee4583/pages/auth/signin/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_931_45zr_Yo_45KzrRdXfBneWVWNQ7Tu71Y1tdNPO1Ju9md5UMeta } from "/tmp/8dd6d5cccee4583/pages/auth/password/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93JHVxc_45kbcTl7nKhihtLnnACbWhfXVfC2yWBSHz_fOG8Meta } from "/tmp/8dd6d5cccee4583/pages/auth/verifyemail/[...slug].vue?macro=true";
import { default as index6DfCkD_iIj1xHMVTcER6Juhjhetca6XzykYVWCo5j1sMeta } from "/tmp/8dd6d5cccee4583/pages/job/[job]/applicants/index.vue?macro=true";
import { default as _91applicant_93zs0dmi6_45TQHHaSxfzmddcEQM0KUo8lz_cpqtvgq2L3UMeta } from "/tmp/8dd6d5cccee4583/pages/job/[job]/applicants/[applicant].vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    meta: indexsrNpwRvaofR5r2ycGkVINeQhqpL6N5UMkDQB0J3YuXAMeta || {},
    component: () => import("/tmp/8dd6d5cccee4583/pages/index.vue")
  },
  {
    name: "norole",
    path: "/norole",
    meta: noroleOZBe5PWTN_66BM5EkIDBmeweJjxo___o8onScPfXhcAMeta || {},
    component: () => import("/tmp/8dd6d5cccee4583/pages/norole.vue")
  },
  {
    name: "job-new",
    path: "/job/new",
    meta: newFWq4zr0FOv7euui9a7nrrhDQMAqtkXF_nGSBVFQjevcMeta || {},
    component: () => import("/tmp/8dd6d5cccee4583/pages/job/new.vue")
  },
  {
    name: "settings",
    path: "/settings",
    meta: settingsuifP75_456qr3tLTQ0QVJF6X3PNfXZNEhLJwi_mmRaXvEMeta || {},
    component: () => import("/tmp/8dd6d5cccee4583/pages/settings.vue")
  },
  {
    name: "nocompany",
    path: "/nocompany",
    meta: nocompanygcN2bBVgmYFDxY8fAyRx86DWXybtOFTK8knQNXdTVMEMeta || {},
    component: () => import("/tmp/8dd6d5cccee4583/pages/nocompany.vue")
  },
  {
    name: "apply-id",
    path: "/apply/:id()",
    component: () => import("/tmp/8dd6d5cccee4583/pages/apply/[id].vue")
  },
  {
    name: "job-closed",
    path: "/job/closed",
    meta: closedCJMeDsH7W7CwZ4DU2H6TrueIRCSgZlr7rq1A8a2xfQ8Meta || {},
    component: () => import("/tmp/8dd6d5cccee4583/pages/job/closed.vue")
  },
  {
    name: "job-opened",
    path: "/job/opened",
    meta: opened9oGLJfOB5vDaD_uRAPqgUjmZ8_4gi_45i_L5zCY4kleUMMeta || {},
    component: () => import("/tmp/8dd6d5cccee4583/pages/job/opened.vue")
  },
  {
    name: "job-paused",
    path: "/job/paused",
    meta: pausedwXawqmSEeqwQ7i3aVQfYyM_xu_45lSTQ5NyarSpNiLQVYMeta || {},
    component: () => import("/tmp/8dd6d5cccee4583/pages/job/paused.vue")
  },
  {
    name: "team",
    path: "/team",
    meta: indexZzoWmD2glNkJ3vCMjzwhtG7DHnb3a6QMbxHJ_ZRbxG4Meta || {},
    component: () => import("/tmp/8dd6d5cccee4583/pages/team/index.vue")
  },
  {
    name: "user",
    path: "/user",
    meta: index3hXZ_QaSdnjqiIprk0_45t0A688Ki5nxWJpX_453kPWpiJEMeta || {},
    component: () => import("/tmp/8dd6d5cccee4583/pages/user/index.vue")
  },
  {
    name: "inbox",
    path: "/inbox",
    meta: indexM7rHDbZKywvrYwt_Vo824Id6x0zMSYndu49TMjlk07cMeta || {},
    component: () => import("/tmp/8dd6d5cccee4583/pages/inbox/index.vue")
  },
  {
    name: "plans",
    path: "/plans",
    meta: index6_N_45b9X2GFzNcPfW6rowQj6_YvCVFlcJHqup3WM8jCUMeta || {},
    component: () => import("/tmp/8dd6d5cccee4583/pages/plans/index.vue")
  },
  {
    name: "apply-thanks",
    path: "/apply/thanks",
    component: () => import("/tmp/8dd6d5cccee4583/pages/apply/thanks.vue")
  },
  {
    name: "job-archived",
    path: "/job/archived",
    meta: archivedeFmx3tOXcATc3Up_45zhLd316K_45eZU9QwYajDA_45YSJ0skMeta || {},
    component: () => import("/tmp/8dd6d5cccee4583/pages/job/archived.vue")
  },
  {
    name: "thanks",
    path: "/thanks",
    meta: indexZbszxocWLw7G9_45G0gYy2KusiiC8iSAE_k9weuw1cyTUMeta || {},
    component: () => import("/tmp/8dd6d5cccee4583/pages/thanks/index.vue")
  },
  {
    name: "company",
    path: "/company",
    meta: indexca3IQStoQdwmGMdM33JR320RAxXX7CtlnsMuGZazFCsMeta || {},
    component: () => import("/tmp/8dd6d5cccee4583/pages/company/index.vue")
  },
  {
    name: "applicant-id",
    path: "/applicant/:id()",
    meta: _91id_93He_UUqcCygq0Tl6pte6RGZjDashtaU_45xDqzt0k37EGEMeta || {},
    component: () => import("/tmp/8dd6d5cccee4583/pages/applicant/[id].vue")
  },
  {
    name: "inbox-mentions",
    path: "/inbox/mentions",
    meta: mentionsAuw9ibmmBZOrwQAiyfWMWHeNzgK1qVNfHXYh_45FJMAM8Meta || {},
    component: () => import("/tmp/8dd6d5cccee4583/pages/inbox/mentions.vue")
  },
  {
    name: "job-job",
    path: "/job/:job()",
    meta: indexeJYlaSuEyD6JKipThjFv4YCGWYgDUb445ZMIYXoaxbwMeta || {},
    component: () => import("/tmp/8dd6d5cccee4583/pages/job/[job]/index.vue")
  },
  {
    name: "apply-perfil-id",
    path: "/apply/perfil/:id()",
    component: () => import("/tmp/8dd6d5cccee4583/pages/apply/perfil/[id].vue")
  },
  {
    name: "auth-signin",
    path: "/auth/signin",
    meta: indexf6vVQ2dqVLH7xTCwZRymdTYHqpcwPEnjZkd7XuwMYlkMeta || {},
    component: () => import("/tmp/8dd6d5cccee4583/pages/auth/signin/index.vue")
  },
  {
    name: "auth-signup",
    path: "/auth/signup",
    meta: indexdMz0ChJINxWlZHp72dpmfFPIeBMfX1WaupWIARjwdTwMeta || {},
    component: () => import("/tmp/8dd6d5cccee4583/pages/auth/signup/index.vue")
  },
  {
    name: "company-id-jobs",
    path: "/company/:id()/jobs",
    component: () => import("/tmp/8dd6d5cccee4583/pages/company/[id]/jobs.vue")
  },
  {
    name: "company-onboarding",
    path: "/company/onboarding",
    meta: onboardingsrAA5K3S1WDMCwP_45xvtORv7cDY5Oq9ECG90wQBBKDJsMeta || {},
    component: () => import("/tmp/8dd6d5cccee4583/pages/company/onboarding.vue")
  },
  {
    name: "auth-signup-email",
    path: "/auth/signup/:email()",
    component: () => import("/tmp/8dd6d5cccee4583/pages/auth/signup/[email].vue")
  },
  {
    name: "job-job-duplicate",
    path: "/job/:job()/duplicate",
    meta: duplicaterSwzD4iYO2yk71cJzINSaqYN7KUjEujAFWnOHqoBpm0Meta || {},
    component: () => import("/tmp/8dd6d5cccee4583/pages/job/[job]/duplicate.vue")
  },
  {
    name: "auth-password-forgot",
    path: "/auth/password/forgot",
    component: () => import("/tmp/8dd6d5cccee4583/pages/auth/password/forgot.vue")
  },
  {
    name: "auth-signin-slug",
    path: "/auth/signin/:slug(.*)*",
    meta: _91_46_46_46slug_93dlzvaeZi13YIJRAdyOXekI12aKMoPrnSOtn5fbovAaoMeta || {},
    component: () => import("/tmp/8dd6d5cccee4583/pages/auth/signin/[...slug].vue")
  },
  {
    name: "auth-password-slug",
    path: "/auth/password/:slug(.*)*",
    component: () => import("/tmp/8dd6d5cccee4583/pages/auth/password/[...slug].vue")
  },
  {
    name: "auth-verifyemail-slug",
    path: "/auth/verifyemail/:slug(.*)*",
    component: () => import("/tmp/8dd6d5cccee4583/pages/auth/verifyemail/[...slug].vue")
  },
  {
    name: "job-job-applicants",
    path: "/job/:job()/applicants",
    meta: index6DfCkD_iIj1xHMVTcER6Juhjhetca6XzykYVWCo5j1sMeta || {},
    component: () => import("/tmp/8dd6d5cccee4583/pages/job/[job]/applicants/index.vue")
  },
  {
    name: "job-job-applicants-applicant",
    path: "/job/:job()/applicants/:applicant()",
    meta: _91applicant_93zs0dmi6_45TQHHaSxfzmddcEQM0KUo8lz_cpqtvgq2L3UMeta || {},
    component: () => import("/tmp/8dd6d5cccee4583/pages/job/[job]/applicants/[applicant].vue")
  }
]